import { EStabOptions } from "@middleware/types";

export const SCORE_MAX = 100;
export const MIN_IMC = 18.5;
export const BILAN_DURATION = 30;
export const IMC_BREAKPOINTS = [0, 18.5, 25, 30, 40, 100];
export const IMC_ROTATION_BREAKPOINTS = [0, 25, 37, 101, 137, 180];
export const BILAN_INFO_STEPS_START_AT = "first_name";
export const BILAN_TIRED_MORNING_STEP = "tired_mornings";
export const BILAN_DIGESTIVE_PROBLEMS_STEP = "digestive_problems";
export const BILAN_SLEEP_PROBLEM_STEP = "sleep_problems";
export const BILAN_EMAIL_STEP = "email";
export const BILAN_HEIGHT_STEP = "height";
export const BILAN_WEIGHT_STEP = "weight";
export const BILAN_IMC_STEP = "imc";
export const BILAN_DESIRED_WEIGHT_STEP = "desired_weight";
export const BILAN_DIETS_IN_10_YEARS_STEP = "diets_in_10_years";
export const BILAN_ACCEPTANCE_FIELD = "acceptance";
export const BILAN_CHAT_STEP = "chat";
export const BILAN_SLOT_STEP = "slot";
export const BILAN_PHONE_STEP = "phone_number";
export const BILAN_TIRED_MORNING_NEVER_VALUE = "morning_fatigue_a";
export const BILAN_DIGESTIVE_PROBLEMS_NEVER_VALUE = "digestion_problems_a";
export const BILAN_0_DIET_IN_10_YEARS_VALUE = "diets_number_a";
export const BILAN_SLEEP_PROBLEM_NEVER_VALUE = "sleep_problems_a";
export const BILAN_GENDER_MAN_VALUE = "gender_a";
export const BILAN_GENDER_WOMAN_VALUE = "gender_b";
export const BILAN_GENDER_STEP = "gender";
export const BILAN_MAX_LOADING_SECONDS = 10;
export const BILAN_GENDER_STORAGE_KEY = "gender";
export const REVIEW_NORMAL_TYPE = "normal";
export const PROGRAM_MAN = "Man";
export const PROGRAM_WOMAN = "Woman";
export const BMI_CATEGORIES = [
  {
    label: "pages.thinness",
    max: 18.5,
  },
  {
    label: "pages.normal",
    min: 18.5,
    max: 25,
  },
  {
    label: "pages.overweight",
    min: 25,
    max: 30,
  },
  {
    label: "pages.moderateObesity",
    min: 30,
    max: 40,
  },
  {
    label: "pages.severeObesity",
    min: 40,
  },
];
export const DURATION_DEFAULT_PROGRAM_DAYS = 5;
export const DURATION_DEFAULT_PROGRAM_WITH_MEALS = false;
export const DURATION_PROGRAM_UP_TO_4KG = "1-4";
export const DURATION_PROGRAM_UP_TO_8KG = "5-8";
export const STAB_PROGRAMS_TO_HIDE = [3, 4];
export const STAB_PROGRAMS_MEALS_TO_HIDE = [
  EStabOptions.SNACK,
  EStabOptions.BREAKFAST,
];
export const STAB_USED_PROGRAMS_TO_HIDE_MEALS = [6, 7];
